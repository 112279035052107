import React from "react";
import VaporDriveVideo from "./videos/vapordrive-650.mp4"
import PomponsAdventureVideo from "./videos/pom-pons-adventure-720.mp4"
import OnePerPersonVideo from "./videos/one-per-person-720.mp4"
import FourPlayerChessVideo from "./videos/4-Player-Chess-720.mp4"
import JennAirShowroomVideo from "./videos/jennair-showroom-demo.mp4"
import KitchenVRVideo from './videos/kitchen-vr-720.mp4'

import ReferencerImage from "./images/referencer.jpg"
import CaesarstoneKitchenImage from "./images/compressed/caesarstone-background.jpg"
import JennAirShowroomImage from "./images/compressed/jennair-showroom-bg-grid.jpg"

const Data = [
	{
		type: "project",
		idName: "caesarstone-vr-kitchen",
		title: "Caesarstone Visualizer",
		subtitle: "Product visualization using VR",
		description: "A product visualization tool that helps prospective consumers/builders better visualize unique countertops finishes in various kitchen settings using virtual reality. This experience was showcased at KBIS (The Kitchen & Bath Industry Show).",
		techList: "Developed in the Unity Engine for Oculus Rift",
		backgroundImage: false,
		buttonText: false,
		buttonURL: false,
		backgroundVideoSrc: KitchenVRVideo,
		videoURL: false,
		videoButtonText: false,
		videoClasses: ""
	},
	{
		type: "project",
		idName: "jennair-showroom",
		title: "Jenn-Air Showroom",
		subtitle: "Touchscreen installations",
		description: "Worked with a team at an agency to build several touchscreen installations allowing visitors to learn more about Jenn-Air appliances at different stations in Jenn-Air showrooms. These installations were deployed in several showrooms nationwide.",
		techList: "Developed with VueJS, Electron & Firebase",
		backgroundImage: JennAirShowroomImage,
		buttonText: false,
		buttonURL: false,
		backgroundVideoSrc: false,
		videoURL: JennAirShowroomVideo,
		videoButtonText: "Watch the interface walkthrough",
		videoClasses: 'video-vertical'
	},
	{
		type: "project",
		idName: "vapordrive",
		title: "VaporDrive",
		subtitle: "A web-based driving game",
		description: "VaporDrive is an interactive web-based driving experiment fusing the expressive sounds of synthwave to a neon metropolis. Currently a WIP.",
		techList: "Created with React, ThreeJS, React-Three-Fiber & Blender",
		buttonText: "Check out VaporDrive",
		buttonURL: "https://vapordrive.namchucks.com",
		backgroundImage: false,
		backgroundVideoSrc: VaporDriveVideo,
		videoURL: false,
		videoButtonText: false,
		videoClasses: ""
	},
	{
		type: "project",
		idName: "referencer",
		title: "Referencer",
		subtitle: "Photo reference images & tools",
		description: "Referencer is a web-based photo reference tool providing curated images for your artistic endeavors (WIP).",
		techList: "Developed with React / NextJS",
		buttonText: "Check out Referencer",
		buttonURL: "https://referencer.app",
		backgroundImage: ReferencerImage,
		backgroundVideoSrc: false,
		videoURL: false,
		videoButtonText: false,
		videoClasses: ""
	},
	{
		type: "project",
		idName: "one-per-person",
		title: "One Per Person",
		subtitle: "Animated short film",
		description: "One per person is an award-winning CGI 3D animated shortfilm by Traceback Studios. I was responsible for character animation on several shots on the film.",
		techList: "Character animator using with Maya 3D",
		buttonText: false,
		buttonURL: false,
		backgroundImage: false,
		backgroundVideoSrc: OnePerPersonVideo,
		videoURL: "//www.youtube.com/embed/AVttlE4Y5c8?rel=0&autoplay=1&fs=1",
		videoButtonText: "Watch the short film",
		videoClasses: ""
	},
	{
		type: "project",
		idName: "four-player-chess",
		title: "4-Player Chess",
		subtitle: "A multiplayer chess game",
		description: "A 4-player 3D chess game currently in development for mobile & desktop.",
		techList: "Developed with Godot & Blender",
		buttonText: false,
		buttonURL: false,
		backgroundImage: false,
		backgroundVideoSrc: FourPlayerChessVideo,
		videoURL: false,
		videoButtonText: false,
		videoClasses: ""
	},
	// {
	// 	type: "project",
	// 	idName: "pom-pons-adventure",
	// 	title: "Pom-Pon's Adventure",
	// 	subtitle: "A mobile platformer/puzzle game",
	// 	description: "This game demo was developed as a personal project with a group of friends. I focused on gameplay mechanics development, sound design & project management.",
	// 	techList: "Developed with Godot Engine",
	// 	buttonText: "Download on Google Play",
	// 	buttonURL: "https://play.google.com/store/apps/details?id=dev.vtx3.pomponsadventure",
	// 	backgroundImage: false,
	// 	backgroundVideoSrc: PomponsAdventureVideo,
	// 	videoURL: "//www.youtube.com/embed/tIqq4PqFP_A?rel=0&autoplay=1&fs=1",
	// 	videoButtonText: "Watch Gameplay Trailer",
	// 	videoClasses: ""
	// },
	{
		type: "footer",
		idName: "contact",
		title: "Get In Touch",
		subtitle: "",
		description: "",
		techList: "",
		buttonText: false,
		buttonURL: false,
		backgroundImage: false,
		backgroundVideoSrc: false,
		videoURL: false,
		videoButtonText: false,
		videoClasses: ""
	}
]

export default Data

