import { useEffect, useState } from "react"

const Navigation = (props) => {

	const [navOpen, setNavOpen] = useState(false)

	const closeMenuHandler = () => {
		setNavOpen(prev => {
			if (!prev)
				document.body.classList.add('nav-open')
			else
				document.body.classList.remove('nav-open')
			return !prev
		})
	}

	return (
		<>
			<nav className={navOpen ? 'nav-open' : ''}>

				<div className="header">
					<a href="#header" onClick={closeMenuHandler}>
						<span>BACK TO TOP</span>
					</a>
				</div>

				{props.data.map( (data) => {
					return <div className={data.idName}>
						<a href={`#${data.idName}`} onClick={closeMenuHandler} title={`Scroll to ${data.title}`}>
							<span>{data.title}</span>
						</a>
					</div>
				})}

			</nav>
			
			<button className="nav-toggle" onClick={closeMenuHandler} title={navOpen ? "Close Menu" : "Open Menu"}></button>

		</>
	)
}

export default Navigation