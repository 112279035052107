import React, { useEffect, useState, useRef, useCallback } from 'react'

const CardLayout = (props) => {

	const [preloadContent, setPreloadContent] = useState(false)
	const [percentageScrolled, setPercentageScrolled] = useState(0)
	const [isInViewState, setIsInViewState] = useState(false)
	const [showPopUp, setshowPopUp] = useState(false)

	const ref = useRef()
	const videoRef = useRef()

	const isCardInView = useCallback((getRef) => {

		const CURRENT_REF = getRef.current
		const CURRENT_SCROLL_POSITION = window.pageYOffset 
		const SCREEN_MIDDLE = window.innerHeight / 2
		const CURRENT_SCROLL_MIDDLE = CURRENT_SCROLL_POSITION + SCREEN_MIDDLE
		const CURRENT_REF_MIDDLE = CURRENT_REF.offsetTop + (CURRENT_REF.offsetHeight / 2)

		// Set In View State
		if (
			CURRENT_SCROLL_POSITION + SCREEN_MIDDLE >= CURRENT_REF.offsetTop &&
			CURRENT_SCROLL_POSITION + SCREEN_MIDDLE <= CURRENT_REF.offsetTop + CURRENT_REF.offsetHeight) {

			setIsInViewState(prev => {
				return prev === false ? CURRENT_REF_MIDDLE > CURRENT_SCROLL_MIDDLE ? 'true-down' : 'true-up' : prev
			})

			if (videoRef.current && videoRef.current.paused)
				videoRef.current.play()

			// get percentage scrolled
			const percentageScrolled = Math.abs(((CURRENT_REF.offsetTop - (CURRENT_SCROLL_POSITION + SCREEN_MIDDLE)) / CURRENT_REF.offsetHeight) * 100).toFixed(0)
			setPercentageScrolled(percentageScrolled)
			// console.log('set scroll percentage')

		} else {
			setIsInViewState(false)
			setshowPopUp(false)
			if (videoRef.current && !videoRef.current.paused)
				videoRef.current.pause()
		}

		// Set Preload State based on how many screen heights away the content is
		const screens = 2.5
		if (CURRENT_SCROLL_POSITION + window.innerHeight * screens > CURRENT_REF.offsetTop)
			setPreloadContent(true)

	}, [])

	useEffect(() => {
		
		// console.log('render card layout: ', props.idName)

		window.addEventListener('scroll', (e) => {
			isCardInView(ref)
		})
		
		isCardInView(ref)

		return (() => {
			window.removeEventListener('scroll', (e) => {
				isCardInView(ref)
			})
		})
	}, [isCardInView, props.idName])

	const openVideoHandler = (e) => {
		e.preventDefault()
		setshowPopUp( prev => !prev)
	}

	return (
		<>
			<section
			id={props.idName}
			className="card-block column-width-10 column-left-1"
			data-in-view={isInViewState}
			data-preloaded={preloadContent}
			ref={ref}>

				<div className="overlay" style={{ transform: `translateY(-${percentageScrolled / 20}rem)` }}>

					<h3>{props.splitToSpan(props.title)}</h3>
					{props.subtitle &&
						<h4 style={{ lineHeight: 0.5 + percentageScrolled / 100 }}>{props.subtitle}</h4>
					}
					{props.description &&
						<p style={{ lineHeight: 1.3 + percentageScrolled / 200 }}>{props.description}</p>
					}

					<div className="button-group">
						{props.buttonURL &&
							<a href={props.buttonURL} className="button button-primary" target="_blank" rel="noreferrer">
								<span className="text">{props.buttonText}</span>
							</a>
						}

						{props.videoURL &&
							<a href="#" onClick={openVideoHandler} className="button button-primary" target="_blank" rel="noreferrer">
								<span className="text">{props.videoButtonText}</span>
							</a>
						}

						{props.type === "footer" &&
							<>
								<a href="https://twitter.com/namchucks" target="_blank" title="Follow me on Twitter" className="twitter">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" fill="#fff" /></svg>
								</a>
								<a href="https://www.linkedin.com/in/nam-pham-63708230/" target="_blank" title="Connect with me on LinkedIn" className="linkedin">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" fill="#fff" /></svg>
								</a>
							</>
						}
					</div>
				</div>

				{props.backgroundImage && preloadContent &&
					<>
						<div className="fader" style={{ opacity: `${percentageScrolled / 1.5}%` }}></div>
						<picture>
							<img src={props.backgroundImage} style={{ transform: `translateY(-${percentageScrolled / 80}rem)` }} alt="" />
						</picture>
					</>
				}

				{props.backgroundVideoSrc && preloadContent &&
					<>
						<div className="fader" style={{ opacity: `${percentageScrolled / 2}%` }}></div>
						<video muted loop style={{ transform: `translateY(-${percentageScrolled / 80}rem)` }} ref={videoRef}>
							<source src={props.backgroundVideoSrc} type="video/mp4" />
						</video>
					</>
				}

				{props.videoURL &&
					<div className={`video-container ${showPopUp ? 'active': ''} ${props.videoClasses}`}>
						<button className="close-video" onClick={openVideoHandler}>Close Video</button>
						<iframe src={showPopUp && props.videoURL}></iframe>
					</div>
				}

			</section>
		</>
	)
}

export default React.memo(CardLayout)