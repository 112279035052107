import './App.scss';
import { useState } from 'react'
import Gridlines from './components/Gridlines'
import Data from './Data.js'
import CardLayout from './components/CardLayout'
import Navigation from './components/Navigation'
import Header from './components/Header'

function App() {

  const splitToSpan = (word) => {
    return word.split('').map( (e, index) => {
      let char
      if (e === '_')
        char = <><br /><span key={index} className="blank">&nbsp;</span></>
      else
        char = <span key={index}>{e !== ' ' ? e : '\xa0'}</span>
      return char
    })
  }

  return (
    <>

      <Header splitToSpan={splitToSpan} />

      { Data.map( (data, index) => <CardLayout {...data} splitToSpan={splitToSpan} key={index} /> ) }

      <Navigation data={Data} />

    </>
  );
}

export default App;
