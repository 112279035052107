import { useState, useEffect } from 'react'
import DownArrow from './../images/down-arrow.svg'

const Header = (props) => {

	const [scrollPosition, setScrollPosition] = useState(0)

	useEffect(() => {

		window.addEventListener('scroll', () => {
			if (window.pageYOffset < window.innerHeight / 2)
				setScrollPosition(window.pageYOffset)
			else
				setScrollPosition(false)
		})

	}, [])

	return (
		<header id="header" data-in-view={scrollPosition !== false ? 'true' : false}>
			<div className="overlay">
				<h1 className="column-left-1">{props.splitToSpan('NAM PHAM')}</h1>
				<h2 className="column-left-1" style={{ lineHeight: 1 + scrollPosition / 200 }}>{props.splitToSpan('CREATIVE DEVELOPER')}</h2>
				<div className="button-group column-left-1">
					<a href="#caesarstone-vr-kitchen" className="button button-primary scroll-down-arrow">
						<span className="slide-in">
							<img src={DownArrow} width="24" height="24" alt="" /> Scroll Down <img src={DownArrow} width="24" height="24"  alt="" />
						</span>
						<span>Check out my work</span>
					</a>
				</div>
			</div>
		</header>
	)
}

export default Header